<template>
    <v-container v-if="addGaleriesDlg">
        <v-dialog v-model="addGaleriesDlg" scrollable max-width="700px">
        <v-card>
            <v-card-title>Galleries {{selectedActivity}}</v-card-title>
            <v-divider></v-divider>
            <v-card-text style="height: 550px;">
            <v-flex py-2>
                <v-layout column>
                    <v-flex xs7 px-2>
                        <span>Youtube Video Link</span>
                        <v-layout wrap>
                            <v-flex>
                                <v-text-field outlined v-model="ytubeLink" dense hide-details=""></v-text-field>
                            </v-flex>
                            <v-flex class="pa-1">
                                <v-btn @click="addVideo()" color="green">Add</v-btn>
                            </v-flex>
                        </v-layout>
                        <v-layout wrap>
                            <v-flex v-for="(video, idx) in videos" :key="idx" class="py-1">
                                <v-card class="pa-3" width="300">
                                    <LazyYoutubeVideo
                                    :src="video.link"
                                    preview-image-size="mqdefault"
                                    />
                                    <v-btn @click="removeVideo(idx)" color="secondary" fab dark x-small bottom right absolute style="bottom:5px!important;right:5px!important">
                                    <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </v-card>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <v-divider class="mt-2"></v-divider>
                    <v-flex xs5 pl-2 pt-3>
                        <v-layout wrap>
                            <v-flex pb-2>
                                <v-btn color="primary" class="text-none" rounded depressed @click="onButtonClick">
                                    <v-icon left>
                                        mdi-plus
                                    </v-icon>
                                    Add Photos
                                </v-btn>
                                <input ref="uploader" class="d-none" type="file" accept="image/*" multiple 
                                    @change="onFileChange">
                                <!-- <v-file-input @change="onFileChange" show-size hide-input prepend-icon="mdi-plus"
                                    multiple accept="image/*" label="File input"
                                ></v-file-input> -->
                            </v-flex>
                        </v-layout>
                        <v-layout wrap>
                            <v-flex v-for="(file, idx) in images" :key="idx">
                                <v-card class="pa-2 ma-1" width="300">
                                    <img :src="file.base64" width="285">
                                    <v-btn @click="removePhoto(idx)" color="secondary" fab dark x-small bottom right absolute style="bottom:5px!important;right:5px!important">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </v-card>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-flex>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="text-right">
            <v-spacer></v-spacer>
            <v-btn @click="addGaleriesDlg=false">Cancel</v-btn>
            <v-btn color="primary" @click="saveGalleries()">Save</v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
        <v-overlay :value="loading" style="z-index:999999">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>            
    </v-container>    
</template>

<script>
import LazyYoutubeVideo from 'vue-lazy-youtube-video'

export default {
    components: {
        LazyYoutubeVideo
    },  
    data: () => ({
        addGaleriesDlg: false,
        videos: [],
        ytubeLink: '',
        loading: false,
        selectedActivity: '',
        images: [],
        // vimages: []
    }),
    methods: {
        openDialog(_id) {
            this.addGaleriesDlg = true
            this.selectedActivity = _id
            this.images = []
            this.videos = []
            this.loading = true
            this.$store.dispatch('gallery/getObj', this.selectedActivity).then((response) => {
                if (response){
                    this.images = response.images
                    this.videos = response.videos
                    this.images.forEach(image => {
                        var url = this.$store.state.config.mediaHost + response._id + '/' + image.name
                        this.toDataURL(url).then(resp=>{
                            image.base64 = resp
                        })
                    });
                }
                this.loading = false
            })
        },
        onButtonClick() {
            this.$refs.uploader.click()
        },
        onFileChange(e){
            e.target.files.forEach(image => {
                this.getBase64(image).then((response)=>{
                    var temp = {}
                    temp.base64 = response
                    temp.name = image.name
                    temp.size = image.size
                    temp.type = image.type
                    temp.lastModified = image.lastModified
                    this.images.push(temp)
                })
            });
            console.log(this.images)
        },
        // showImage(image) {
        //     return URL.createObjectURL(image)
        // },
        saveGalleries(){
            var obj = {}
            obj._id = this.selectedActivity
            obj.images = this.images
            obj.videos = this.videos
            // console.log(JSON.stringify(obj))
            this.loading = true
            this.$store.dispatch('gallery/postObj', obj).then(response => {
                this.loading = false
                if (response.result.ok) {
                    this.addGaleriesDlg = false
                }
            })
            
        },
        addVideo() {
            if (this.validateYouTubeUrl(this.ytubeLink)) {
                this.videos.push({link:this.ytubeLink})
                this.ytubeLink = ""
            }
        },
        removeVideo(idx) {
            this.videos.splice(idx, 1)
        },
        removePhoto(idx) {
            this.images.splice(idx, 1)
        },
        getBase64(file) {
            return new Promise((resolve, reject) => {
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    resolve(reader.result);
                };
                reader.onerror = function (error) {
                    reject('Error: ', error);
                };
            })
        },
        validateYouTubeUrl(url) {    
            if (url != undefined || url != '') {        
                var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
                var match = url.match(regExp);
                if (match && match[2].length == 11) {
                    this.ytubeLink = 'https://www.youtube.com/embed/' + match[2] ;
                    return true
                } else {
                    alert('URL not valid');
                    return false
                    // Do anything for not being valid
                }
            }
        },
        toDataURL(url) { 
            return new Promise((resolve) => {
                fetch(url).then(response => response.blob())
                .then(blob => {
                    this.getBase64(blob).then((response)=>{
                        // console.log(response)
                        resolve(response)
                    })
                })
            })
        }        
    }
}
</script>